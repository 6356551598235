import React from 'react';
import Main from 'layouts/Main';
import {
  Hero,
  StepsOfInvestor,
  // ScheduleCall,
  KeyStatistics,
  VideoSection,
  InvestmentGrowth,
} from './components';

const IndexView = (): JSX.Element => {
  return (
    <>
      <Main bgcolor={'background.paper'}>
        <Hero />
        <KeyStatistics />
        <VideoSection />
        <InvestmentGrowth />
        <StepsOfInvestor />
        {/* <ScheduleCall /> */}
      </Main>
    </>
  );
};

export default IndexView;
